<template>
  <section v-if="user && user !== undefined && account" class="main-wrap">
    <header class="inner-page-header">
      <h1 class="big-title">Clients</h1>

      <router-link v-if="account[0].usersRoles[user.id].role === 'admin'" to="/create-client" class="create-button">
        <div class="bt smaller">
          <p>Create a Client</p>
          <img alt="Plus" class="bt-img" src="@/assets/img/plus-icon.svg" />
        </div>
      </router-link>
    </header>

    <div v-if="clients && clients.length" class="clients-wrap">
      <router-link
        v-for="(client, index) in clients"
        :key="index"
        class="client toggle-options"
        :to="{ name: 'CampaignsList', params: { clientId: client.id } }"
      >
        <contextual-toggle-edit
          v-if="account && account[0] && account[0].usersRoles[user.id].role === 'admin'"
          :client="client"
          :actions="['edit', 'archive', 'delete']"
          :edit-action="editClient"
          :duplicate-action="null"
          :archive-action="archiveClient"
          :delete-action="deleteClient"
        ></contextual-toggle-edit>
        <div class="client-img-wrap">
          <div class="image-wrapper">
            <client-logo v-if="client" :client="client" class-name="is-big"></client-logo>
          </div>
          <div class="gradien-hover"></div>
        </div>
        <p>{{ client.name }}</p>
        <footer>
          <p>{{ client.nbCampaigns ? client.nbCampaigns : '0' }} project{{ client.nbCampaigns > 1 ? 's' : '' }}</p>
        </footer>
      </router-link>
    </div>

    <div v-if="!clients || (clients && !clients.length)" class="no-data-wrap" :class="{ 'no-data': clients && !clients.length }">
      <img alt="Showadz" src="@/assets/img/no-data.svg" />
      <h2 class="big-title">Create your first client</h2>
      <router-link to="/create-client">
        <div class="bt">
          <p>Create a client</p>
          <img alt="Plus" class="bt-img" src="@/assets/img/plus-icon.svg" />
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash'
import ContextualToggleEdit from '@/components/actions/ContextualToggleEdit'
import AccountclientsDB from '@/firebase/account-clients-db'

import ClientLogo from '@/components/clients/ClientLogo'

import { doAlert } from '@/misc/helpers'

export default {
  components: {
    ContextualToggleEdit,
    ClientLogo,
  },

  head: function () {
    return {
      title: {
        inner: 'Clients list',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} Manage or create a client`,
          id: 'desc',
        },
      ],
    }
  },

  computed: {
    ...mapState('app', ['appTitle']),
    ...mapState('authentication', ['user']),
    ...mapState('users', ['account', 'clients']),
  },

  watch: {
    $route: {
      async handler($route) {
        if (!isNil($route)) {
          this.getUserAccount()
          this.refreshClientList()
        }
      },
      immediate: true,
    },
    account: {
      async handler(account) {
        if (!isNil(account)) {
          this.refreshClientList()
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.selectClient(null)
  },

  methods: {
    async editClient(client) {
      this.$router.push(`/${client.id}/edit-client`)
    },

    async duplicateItem(client) {
      this.$swal({
        title: 'Duplicate',
        text: `Are you sure you want to duplicate ${client.name}?`,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        imageUrl: '/img/icons/duplicate.svg',
        confirmButtonColor: '#4628FF',
        cancelButtonColor: '#637082',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          const accountClientsDbRef = new AccountclientsDB(this.account[0].id)
          const newAdz = { ...client }
          newAdz.name = `${newAdz.name} (copy)`
          delete newAdz.id
          accountClientsDbRef.create(newAdz)
          doAlert({ title: `${client.name} duplicated`, type: 'success' })

          this.refreshClientList()
        }
      })
    },

    async archiveClient(client) {
      this.$swal({
        title: 'Archive',
        text: `Are you sure you want to archive ${client.name}?`,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        imageUrl: '/img/icons/archive.svg',
        confirmButtonColor: '#4628FF',
        cancelButtonColor: '#637082',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          const accountClientsDbRef = new AccountclientsDB(this.account[0].id)
          const newClient = { ...client, isArchived: true }

          accountClientsDbRef.update(newClient)

          doAlert({ title: `${client.name} archived`, type: 'success' })

          this.refreshClientList()
        }
      })
    },

    async deleteClient(client) {
      this.$swal({
        title: 'Delete',
        text: `Are you sure you want to delete ${client.name}?`,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        imageUrl: '/img/icons/delete.svg',
        confirmButtonColor: '#4628FF',
        cancelButtonColor: '#637082',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.value) {
          const accountClientsDbRef = new AccountclientsDB(this.account[0].id)
          await accountClientsDbRef.delete(client.id)
          doAlert({ title: `${client.name} deleted`, type: 'success' })

          this.refreshClientList()
        }
      })
    },

    refreshClientList() {
      this.getUserClients()
    },

    ...mapActions('users', ['getUserAccount', 'getUserClients', 'selectClient']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';
@import '@/theme/nodata.scss';
@import '@/theme/page-title.scss';

.clients-wrap {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -20px;
}

.test {
  display: none;
}

.create-button {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: $dark-grey;
}

.client {
  position: relative;
  width: calc(33% - 20px);
  min-height: 280px;
  padding-top: 35px;
  margin: 0 10px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #e0e2e6;
  text-align: center;
  font-size: 16px;
  color: $dark-grey;
  font-weight: 600;

  &:hover {
    .client-img-wrap {
      transform: scale(1.1);
    }
    .gradien-hover {
      opacity: 1;
    }
    .empty-image-letter {
      color: #fff;
    }
  }

  .client-img-wrap {
    position: relative;
    display: inline-block;
    width: 110px;
    margin-bottom: 5px;
    border-radius: 60px;
    padding: 5px;
    background-color: #eff1f2;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    img {
      border-radius: 50%;
    }

    .image-wrapper {
      width: 100px;
      height: 100px;
    }

    .empty-image-letter {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      font-size: 30px;
      font-weight: 900;

      z-index: 9;

      transition: all 0.3s ease-in-out;
    }
  }

  .gradien-hover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 55px;
    width: 110px;
    height: 110px;
    background: linear-gradient(to right, #2e7df2, #8a23f1);
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  img {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: block;
  }

  footer {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    margin-top: 35px;
    font-size: 14px;
    border-top: 1px solid #e0e2e6;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
</style>
